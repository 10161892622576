import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import { AuthTokens, SharedFeatAuthModule } from '@features/auth';
import {
  BASE_PATH as HELP_PAGES_BASE_PATH,
  HELP_PAGES_CONFIG,
  HelpPagesConfig,
} from '@paldesk/design-system/feature/help-page';
import { BASE_PATH as USER_PROFILE_BASE_PATH } from '@paldesk/shared-lib/data-access/identity-service-generated';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { environment } from '../environments/environment';
import { BASE_PATH } from './shared/generated';

@NgModule({
  imports: [
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    SharedFeatAuthModule.forRoot(),
  ],

  providers: [
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: BPM_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/bpm',
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.POST_LOGOUT_REDIRECT_URI,
    },
    {
      provide: BASE_PATH,
      useFactory: () => environment.palfinger_api_management + '/ms-palshow', // override this to localhost if you want to access FE and BE on localhost
    },

    // table settings
    {
      provide: USER_PROFILE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    // help pages
    {
      provide: HELP_PAGES_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/ms-wiki',
    },
    {
      provide: HELP_PAGES_CONFIG,
      useFactory: () =>
        ({
          pagePath: '/Help pages/Palshow',
          projectName: 'Palfinger.Paldesk',
          wikiName: 'Palfinger.Paldesk.wiki',
          appTitle: 'Palshow',
        }) as HelpPagesConfig,
    },
  ],
})
export class CoreModule {}
